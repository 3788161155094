import {
  CreatorCLogo,
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  MailIcon,
  XIcon,
} from "../components/Icons";

import { Link } from "react-router-dom";

const Footer = () => {
  // Footer Functions - START
  const navigateToLinkedIn = () => {
    window.open("https://www.linkedin.com/company/vitrinelive/", "_blank");
  };

  const navigateToX = () => {
    window.open(
      "https://x.com/vitrine_live?s=21&t=RIJgSPeiVhMOIy5-Xqd9ww/",
      "_blank"
    );
  };

  const navigateToInstagram = () => {
    window.open(
      "https://instagram.com/vitrinelive?igshid=MzMyNGUyNmU2YQ==/",
      "_blank"
    );
  };
  // Footer Functions - END

  return (
    <div className="flex flex-col relative">
      <div className="flex flex-col w-full justify-center px-8 md:px-16 md:pt-6 pb-24 gap-4 z-20 mt-32">
        <div className="flex flex-col md:flex-row md:justify-between justify-center">
          <CreatorCLogo className="w-32 h-11 mb-8 md:mb-0 mx-auto md:mx-0" />
          <div className="flex flex-row gap-6 justify-center md:justify-end">
            <MailIcon className="cursor-pointer" />
            <FacebookIcon className="cursor-pointer" />
            <XIcon onClick={navigateToX} className="cursor-pointer" />
            <InstagramIcon
              onClick={navigateToInstagram}
              className="cursor-pointer"
            />
            <LinkedInIcon
              onClick={navigateToLinkedIn}
              className="cursor-pointer"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between">
          <p className="px-8 md:px-0 pb-6 md:pb-0 text-center md:text-left">
            Copyright © 2023.All Rights Reserved By Vitrine
          </p>
          <div className="flex flex-row gap-6 justify-between md:justify-end">
            <Link className="" to="/terms-and-conditions">
              Terms and Conditions
            </Link>
            <Link className="" to="/privacy-policy">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
