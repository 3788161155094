import CubeOne from "../assets/landing-page/cube-1.webp";
import CubeTwo from "../assets/landing-page/cube-2.webp";
import CubeThree from "../assets/landing-page/cube-3.webp";

import ContinuousStrip from "../components/LandingPageStrip";
import { motion } from "framer-motion";

import { useState } from "react";
import { ArrowRight, CreatorCLogo, HoverBackground } from "./Icons";

const AnimatedTile = (props) => {
  const [tileHover, setTileHover] = useState(false);

  // const toggleHover = () => {
  //   setTileHover((prev) => !prev);
  // };

  return (
    <div
    className="relative flex flex-col p-2 md:p-6 w-full md:w-1/3 box-border border border-[rgba(84,154,156,0.1)] rounded-lg cursor-pointer"
    onMouseEnter={() => setTileHover(true)}
    onMouseLeave={() => setTileHover(false)}
  >
    {/* Background Blur */}
    <div className="absolute inset-0 bg-white/10 backdrop-blur-[25px] rounded-lg -z-10"></div>

    {/* Content */}
    <motion.div
      className="absolute mx-auto z-0 w-full h-full -left-16 -top-32"
      style={{
        opacity: tileHover ? 1 : 0,
        transition: "opacity 0.3s ease-in-out",
      }}
    >
      <HoverBackground />
    </motion.div>

    <div className="relative flex flex-col justify-end items-center overflow-hidden z-10">
      {/* Image with Hover Effect */}
      <motion.img
        className="w-[20rem]"
        src={props.image}
        initial={{ scale: 1, rotate: 0 }}
        animate={{
          scale: tileHover ? 1.3 : 1,
          rotate: tileHover ? -6.5 : 0,
        }}
        transition={{
          duration: 0.5,
          ease: "easeInOut",
        }}
      />

      {/* Floating Cubes */}
      {tileHover && (
        <>
          <motion.img
            className="absolute left-2 top-24 md:left-12 md:top-40"
            src={CubeOne}
            animate={{ y: ["0%", "-50%", "0%"] }}
            transition={{ duration: 4, repeat: Infinity, ease: "easeInOut" }}
          />
          <motion.img
            className="absolute top-8 right-4 md:top-8 md:right-20"
            src={CubeTwo}
            animate={{ y: ["0%", "-50%", "0%"] }}
            transition={{ duration: 4, repeat: Infinity, ease: "easeInOut" }}
          />
          <motion.img
            className="absolute top-20 right-10 md:top-60 md:right-16"
            src={CubeThree}
            animate={{ y: ["0%", "50%", "0%"] }}
            transition={{ duration: 4, repeat: Infinity, ease: "easeInOut" }}
          />
        </>
      )}

      {/* Title and Logo */}
      <div className={`flex flex-row items-center gap-2 ${tileHover ? "mt-8" : ""}`}>
        <CreatorCLogo className="-mt-3" />
        <span className="font-bold text-xl md:text-[32px] md:leading-[44px]">
          {props.title}
        </span>
      </div>

      {/* Subtitle and Button */}
      {tileHover && (
        <motion.div
          className="flex flex-col items-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
        >
          <span className="md:mt-4 text-base md:text-lg break-words">{props.subtitle}</span>
          <div
            className="flex flex-row font-semibold text-base text-primary mt-4 cursor-pointer"
            onClick={props.onClick}
          >
            {props.buttonText}&nbsp;&nbsp;
            <ArrowRight />
          </div>
          <ContinuousStrip
            heading={props.stripTitle}
            subtitle={props.stripSubtitle}
          />
        </motion.div>
      )}
    </div>
  </div>
  );
};

export default AnimatedTile;
