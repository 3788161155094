import React from "react";
import NavBar from "../components/NavBar";
import GlobeImage from "../components/GlobeImage";
import { ArrowUp } from "../components/Icons";
import Footer from "../components/Footer";

const About = () => {
  return (
    <div className="overflow-y-auto overflow-x-hidden !font-bricolage bg-light-blue">
      <NavBar />
      <div className="py-6 px-8 md:py-10 md:px-16">
        <div className="bg-white shadow-custom-1 rounded-[20px] p-8 border border-custom-1">
          <h3 className="text-3xl font-bold capitalize leading-none mb-4">
            About Us
          </h3>
          <p className="text-base font-normal leading-10 tracking-normal text-left text-grey-700">
            We founded Vitrine with a vision: to unrestrict the full potential
            of creativity and lead a new era for content creators worldwide. At
            the heart of our mission lies a commitment to not just envision but
            actively shape a more vibrant, equitable, and innovative creator
            economy. Getting closer to creators worldwide recurring issues were
            identified regarding brand collaborations: unpaid work, ghosting
            post-delivery, exploitation of content without fair pay, and brands'
            reputations being tarnished by scams. These challenges not only
            affect individuals but also hinder the growth and health of our
            industry at large. In response to these widespread issues, we
            developed Creator Catalogue - our no cost platform designed to
            facilitate how creators collaborate with brands. Creator Catalogue
            addresses the critical pain points directly, offering secure,
            transparent and equitable partnerships that protect and empower
            creators and brands. Creator Catalogue is more than a solution to
            influencer marketing problems; it's a step towards setting a new
            standard in creator-brand collaborations. We envision a thriving
            ecosystem where creativity is valued, rights are respected, and each
            collaboration contributes to a fairer creator economy. We invite you
            to explore Creator Catalogue. Together, we can create a future where
            every creator's potential is fully realized.
          </p>

          <h4 className="text-2xl font-bold capitalize leading-none my-4  text-center">
            Our<span className="text-primary"> Values</span>
          </h4>

          <div className="flex flex-col md:flex-row gap-4">
            {/* <!-- First Column --> */}
            <div className="w-full md:w-1/3">
              <div className="space-y-6">
                {/* <!-- First Box --> */}
                <div className="mt-5 border border-custom-1 rounded-lg p-4">
                  <div className="flex justify-between items-center">
                    <div className="w-2 h-2 rounded-full bg-primary hidden md:inline-block"></div>
                    <h6 className="text-sm font-semibold">
                      Embrace the Whole Story
                    </h6>
                    <ArrowUp className="hidden md:inline-block" />
                  </div>
                  <div className="mt-2 text-grey-700 text-sm">
                    At Vitrine, we see every creator and fan as an essential
                    part of our narrative. This holistic approach informs our
                    decision-making, ensuring our platform serves diverse needs
                    equitably. It's not just a platform it's a movement shaping
                    the social entertainment evolution.
                  </div>
                </div>

                {/* <!-- Second Box --> */}
                <div className="border border-custom-1 rounded-lg p-4">
                  <div className="flex justify-between items-center">
                    <div className="w-2 h-2 rounded-full bg-primary hidden md:inline-block"></div>
                    <h6 className="text-sm font-semibold">
                      Crafting Ideas, Building with Creators
                    </h6>
                    <ArrowUp className="hidden md:inline-block" />
                  </div>
                  <div className="mt-2 text-grey-700 text-sm">
                    Creativity is the lifeblood of the industry and Vitrine,
                    driving our collaborative efforts. Our team doesn't just
                    work together; we build innovative solutions with a shared
                    passion.
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Second Column (Image) --> */}
            <div className="w-full md:w-1/3 flex items-center justify-center">
              <span className="w-full max-w-[400px]">
                <GlobeImage width="400" height="400" />
              </span>
            </div>

            {/* <!-- Third Column --> */}
            <div className="w-full md:w-1/3 md:ml-3">
              <div className="space-y-6">
                {/* <!-- First Box --> */}
                <div className="border border-custom-1 rounded-lg p-4">
                  <div className="flex justify-between items-center">
                    <div className="w-2 h-2 rounded-full bg-primary hidden md:inline-block"></div>
                    <h6 className="text-sm font-semibold">Clear as Glass</h6>
                    <ArrowUp className="hidden md:inline-block" />
                  </div>
                  <div className="mt-2 text-grey-700 text-sm">
                    Transparency is our operational backbone, ensuring trust,
                    clarity, and recognition our platform operates with the
                    clarity of a vitrine.
                  </div>
                </div>

                {/* <!-- Second Box --> */}
                <div className="border border-custom-1 rounded-lg p-4">
                  <div className="flex justify-between items-center">
                    <div className="w-2 h-2 rounded-full bg-primary hidden md:inline-block"></div>
                    <h6 className="text-sm font-semibold">
                      Embrace the Unknown
                    </h6>
                    <ArrowUp className="hidden md:inline-block" />
                  </div>
                  <div className="mt-2 text-grey-700 text-sm">
                    Curiosity and adaptability are the engines driving Vitrine's
                    innovation. We eagerly explore uncharted territories,
                    ensuring we're always evolving with the dynamic creator
                    economy.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
