// Images Import
import AgencyHubLogo from "../assets/landing-page/agency-hub-logo.png";
import CreatorCatalogueLogo from "../assets/landing-page/creator-catalogue-logo.png";
import ExchangeLogo from "../assets/landing-page/exchange-logo.png";

import AnimatedTile from "../components/AnimatedTile";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";



// import React, { useState, useEffect } from "react";

// const useIsPhone = () => {
//   const [isPhone, setIsPhone] = useState(false);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsPhone(window.innerWidth <= 768); // Adjust breakpoint as needed
//     };

//     handleResize(); // Initial check
//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   return isPhone;
// };

const HomePage = () => {
  // const isPhone = useIsPhone();

  return (
    <div className="overflow-y-auto overflow-x-hidden !font-bricolage bg-light-blue">
      <NavBar />
      {/* Home - Start */}
      <div className="flex flex-col overflow-x-hidden overflow-y-hidden py-10 md:py-20">
        <h2 className="!font-bold text-[48px] md:text-[80px] leading-[48px] md:leading-[80px] bg-gradient-text bg-clip-text text-transparent text-center">
          Paving clear paths
          <br />
          for content creators
        </h2>
        <div className="flex flex-col md:flex-row gap-4 py-10 px-8 md:py-20 md:px-16">
          <AnimatedTile
            title="Creator Catalogue"
            subtitle="Connect, Collaborate and Control"
            image={CreatorCatalogueLogo}
            buttonText="Explore Catalogue"
            stripTitle="Our solution to Creator Collaborations"
            stripSubtitle="Coming Q4 ’24"
            onClick={() => {
              window.location.href = "https://catalogue.vitrine.live";
            }}
          />
          <AnimatedTile
            title="Exchange"
            subtitle="Trade royalties and earn with your favorite creators"
            image={ExchangeLogo}
            buttonText="Start Investing"
            stripTitle="Our solution to Creator Financing"
            stripSubtitle="In Development"
            onClick={() => {}}
          />
          <AnimatedTile
            title="Agency Hub"
            subtitle="Manage, Scale, Collaborate for Marketing Agencies"
            image={AgencyHubLogo}
            buttonText="Get Started with Agencies"
            stripTitle="Our solution for Marketing Agencies"
            stripSubtitle="Coming Q1 ’25"
            onClick={() => {}}
          />
        </div>
      </div>
      {/* Home - End */}
      <Footer />
    </div>
  );
};

export default HomePage;
