import { CreatorCLogo } from "../components/Icons";
import { Link } from "react-router-dom";

const NavBar = () => {
  return (
    <div className="flex flex-row px-8 md:px-16 py-5">
      <div className="flex flex-row w-3/5 md:w-4/6 items-end">
        <CreatorCLogo className="w-32 h-11" />
      </div>
      <div className="flex flex-row gap-4 items-center justify-end w-2/5 md:w-2/6 text-sm md:text-base">
        <Link to="/">
          <span className=" font-semibold text-primary ">Home</span>
        </Link>
        <Link to="/about">
          <span className="font-semibold text-primary ">About Us</span>
        </Link>
      </div>
    </div>
  );
};

export default NavBar;
